// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-typography-tsx": () => import("./../../src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */),
  "component---src-templates-recipe-template-tsx": () => import("./../../src/templates/RecipeTemplate.tsx" /* webpackChunkName: "component---src-templates-recipe-template-tsx" */)
}

